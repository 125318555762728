import React from 'react';
import About from '../../assets/About_Img.png';
import LineBreak2 from '../../assets/Horizontal_Lines/Line_Break_02.png';
import LineBreak1 from '../../assets/Horizontal_Lines/Line_Break_01.png';
import Token from '../../assets/Token01.png';
import PlayNow from '../../assets/PlayNow.png'
import IconRocket from '../../assets/ion_rocket.png'
import './Page2.css';

const Page2 = () => {
    return (
        <div className="page2">
            <div className='about'>
                <div className='left'>
                    <div className='heading' style={{ marginBottom: '50px', marginTop: "20px", fontSize: "105px" }}>
                        Play CZ RUNZ!
                    </div>
                    <div className='text'>
                        Play CZ RUNZ! Sprint through dynamic worlds, dodge obstacles, collect coins, and power-ups. Test your reflexes & play to earn in this thrilling endless runner! How far can you go?
                    </div>
                    <div style={{marginTop:"80px"}}>
                    <img src={PlayNow} alt="About" className="about-img" />
                    </div>
                </div>
                <div className='right'>
                    <img src={About} alt="About" className="about-img" />
                </div>
            </div>
            <img src={LineBreak2} alt="Line Break 2" className="line-break-2" />
            <div className='content' id="tokenomics">
                        <div style={{width:"910px", fontSize:"120px"}} className="revolution-heading">The revolution <br /> is already happening. </div>
                <div className='content-info'>
                    <div className='content-left'>
                        <div className='revolution-text-heading'>Whether we accept it or not.</div>
                        <div className='revolution-text'>Tokenomics: 4,444,444,444</div>
                        <div className='revolution-token-text'>Ticker: $CZRUNZ</div>
                    </div>
                    <div className='content-right'>
                        <img src={Token} alt="Token" className="img-token" />
                    </div>
                </div>
                <div className='revolution-text'>Community 50%, Team & Advisors, 15% Marketing, 12% Airdrop, <br /> 
                3% Ecosystem 10%, CEX Listing 5%, Liquidity 5%</div>
                <div className="buy-button-long" style={{marginTop:'100px'}}>
                    <span className='buy-button-long-text'>BUY $CZRUNZ</span> 
                    <span>
                    <img src={IconRocket} alt="About" className="IconRocket-button" />
                    </span>
                </div>
            </div>
            <img src={LineBreak1} alt="Line Break 1" className="line-break-1" />
        </div>
    );
}

export default Page2;
