import Video from '../../assets/Animation.mp4';
import Rectangle from '../../assets/Rectangle.png';
import YT from '../../assets/Icons/YT.png';
import Telegram from '../../assets/Icons/Telegrsm.png';
import X from '../../assets/Icons/X.png';
import Tiktok from '../../assets/Icons/Tik Tok.png';
import './Page5.css';
import { useEffect, useRef } from 'react';

const Page5 = () => {

    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        if (videoElement) {
            videoElement.play().catch(error => {
            });

            const handleVideoEnd = () => {
                videoElement.currentTime = 0;
                videoElement.play().catch(error => {
                });
            };

            videoElement.addEventListener('ended', handleVideoEnd);

            return () => {
                videoElement.removeEventListener('ended', handleVideoEnd);
            };
        }
    }, []);

    return (
        <div className='page5' style={{ marginTop: "-11px" }}>
              <video ref={videoRef} className='sample-video' Autoplay muted>
                <source src='https://czrun-cdn.s3.ap-southeast-1.amazonaws.com/I_Endorse_CZ_runz.mp4' type="video/mp4" />
            </video>
            {/* <img src={Rectangle} alt="rectangle" style={{ width: "100%", height: "100%" }} /> */}
            <div className='bottom-section'>
                <div className='left'>
                    <div className='icons-wrapper'>
                        <a href="https://www.youtube.com/@CZrunz" target="_blank" className='social-icon'>
                            <img src={YT} alt='youtube' className='img' />
                        </a>
                        <a href="https://t.me/CZRUNZ" target='_blank' className='social-icon'>
                            <img src={Telegram} alt='telegram' className='extra-padding img' />
                        </a>
                        <a href="https://x.com/CzRunz" target='_blank' className='social-icon'>
                            <img src={X} alt='x' className='extra-padding twitter img' />
                        </a>
                        <a href="https://www.tiktok.com/@czrunz" target='_blank' className='social-icon tiktok'>
                            <img src={Tiktok} alt='tiktok' className='extra-padding img' />
                        </a>
                    </div>
                    <div className='bottom-text'>For all enquires please email us </div>
                    <div className='bottom-text'>@spindegens@czrunz.com</div>
                    <div className='bottom-info disclaimer'>
                        Please be advised that CZRUNZ Memecoin is a cryptocurrency created for entertainment purposes only and is not affiliated with or endorsed by (CZ) Changpeng Zhao While the Memecoin may reference or incorporate elements related CZ’s persona, image, or likeness, it does not imply any direct endorsement, partnership, or approval by CZ. Any resemblance or association between the CZRUNZ website, animations, games, and Memecoin and CZ is purely coincidental and intended for satirical or fun and humorous purposes.
                    </div>
                    <div className='bottom-info copy-right'>© 2024 CZRUNZ</div>
                </div>
                <div className='right'>
                    <div className='buy-button-medium'>
                        BUY $CZRUNZ
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Page5;